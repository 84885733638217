import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import { Link } from "gatsby"
import styled from 'styled-components'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'




const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 &:last-child{border-bottom:0; padding-bottom:0;}
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:italic;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`


function ManufacturingPage() {
  return (
    <Layout pageName="industry">
      <SEO
        title="Business Technology Solutions for Manufacturing Industry"
        description="Looking for a business technology solution company for your manufacturing business? WYN Technologies provides state-of-the-art technology solutions that are custom-made, reliable, and budget-friendly at the same time. Get your quote today."/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Manufacturing</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Business Technology Solutions for Manufacturers</GroceryTitle>
              <p>Leading a manufacturing enterprise comes with all sorts of logistical challenges. Modern IT continues to find ways to help automate many essential processes and make them less cumbersome to navigate and manage. But it’s not just about production-specific technology; there are several advances in business technology which can help you and your business, too.</p>
              <p><strong>WYN Technologies</strong> has installed many helpful technologies for manufacturing and production facilities, and we can help yours, too. Whether you’re looking for the right business network setup, WiFi access points, VOIP phone systems, video surveillance systems, or other useful technology solutions, we’ve got you covered!</p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/industry-1.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">Some of the Services We Offer for Manufacturing Businesses and Companies</GroceryIndustryTitle>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-2.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Voice and Data Cabling, Including Fiber</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Having the right voice/data cabling network is important to most any modern business, and that certainly applies to manufacturing entities and organizations, too. Fortunately for you, <strong>WYN</strong> specializes in the design and installation of effective structured cabling, network racking, and data closet solutions for businesses of all types. Need some remediation of your existing cabling infrastructure? We can handle that, too.</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList> 
           <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-3.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">WiFi Access Points & Heat Mapping Services</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>It’s also essential to have an effective wireless network in place for your manufacturing and production business. Many of the devices you use depend on it, your employees need it, and your clients & visitors have come to expect it. No worries, WYN Technologies can help design it and install it for you! We can set up new wireless access points, and we can also utilize wireless heat mapping to identify and correct any current problem areas or dead spots that may exist within your current WiFi coverage area.</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
           <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-7.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Moves, Adds, & Changes (MAC Services)</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>The manufacturing industry is always growing, expanding, and moving – and so is your business. Planning a facility remodel, or moving to a different location? WYN can help things go more smoothly by providing comprehensive de-installation of legacy materials, and we can help with relocation and new installation services, too. In addition, we can set up any structured cabling, wireless network, surveillance & security system, access control system, or anything else you may need.</p>
                  <PrimaryLinkButton text="View Full Detail" />
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList> 
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-12.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Access Control Technology</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Of course, having secure access control for your facility is mission critical, too. You need an effective way to manage who can gain access to your building and when, and it’s also advantageous to be able to offer several keyless access solutions. Whether you prefer PIN pads, proximity cards, keychain fobs, mobile device connectivity, or biometric security features, WYN can provide and install whatever hardware & software configuration you prefer.</p>
                  <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link>
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
           <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-12.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">IT Support Services</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Wish you had some reliable business technology IT support for your manufacturing business? We get it. We understand how it feels to find yourself being “ghosted” by a technology provider – we’ve been on the receiving end of that, too. We’re also happy to say that that’s not how WYN Technologies operates. We’re here whenever you may need us, and we’re also pleased to offer service-after-the-sale contracts to keep your equipment running smoothly for you down the road. You’ve already got enough to worry about, without having to be concerned about business technology maintenance!</p>
                  <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link>
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
           <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/our-service-12.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Equipment Staging, Warehousing, & Storage Services</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>WYN has a complete staging facility for getting your new equipment set up and ready-to-go, before we ever show up on the day of installation. By getting everything configured ahead of time, we’re able to shorten the install process and help make everything go much more smoothly for you. We also have a large, security-monitored warehouse available, should you happen to have any other equipment storage needs. We also give you the option to take advantage of our depot repair services – sometimes choosing a refurbished equipment option is much more cost-effective than having to purchase a brand-new item.</p>
                  <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link>
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
        </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
           <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                {/* <h2 className="h1">Content Heading</h2> */}
              </MarkdownContentTitle>
            {/*  <ComingSoon textAlign="center">Coming Soon...</ComingSoon> */}
               <MarkdownContent>
                  <h2>Tracking Systems for Vehicles and Assets</h2>
                  <p>Let’s face it, if you’re someone who provides leadership for a manufacturing operation, there are A LOT of moving parts to try and manage – but you only have one set of eyes. If only there were some good technological solutions designed to help you keep track of all those important pieces and parts … but there are! And <strong>WYN Technologies</strong> can help you implement powerful tools like these to help streamline your business!</p>
                  <ul>
                    <li>
                    <strong>GPS for Vehicle Tracking</strong>
                    <p>If you’re a manufacturer, the chances are good that you operate several company vehicles, either for delivery, materials procurement, general business needs, or all of the above. Would you like to be able to keep a better account of vehicle location, ETA, mileage, costs, etc.? Letting WYN install a vehicle GPS tracking system for you can serve all of these needs, and more.</p>
                    </li>
                    <li>
                    <strong>RFID for Asset Tracking</strong>
                    <p>Smart businesses like yours use Radio Frequency Identification (RFID) systems to track everything from valuable assets, to equipment, to designated components, to individual parts as they move down the production chain. RFID technology enables you to manage both inventory & logistics for your manufacturing business much more efficiently, and does so with less required labor or staffing. Get in touch with us to explore your best RFID solution options!</p>
                    </li>
                        </ul>
                </MarkdownContent>
               <MarkdownContent>
                    <h2>Why Choose WYN Technologies for Your Manufacturing Business Technology Needs</h2>
                  <ul>
                    <li>
                    <strong>Tested-and-Approved Custom Solutions for You</strong>
                    <p>At WYN, we’re specially equipped to be able to fully stage, configure, and test your chosen devices and products ahead of time in our dedicated staging facility, before we ever come out to install.</p>
                    </li>
                    <li>
                    <strong>Only the Highest-Quality Products & Services</strong>
                    <p>WYN Technologies only partners with the best business technology brands and manufacturers. And our level of installation workmanship and ongoing personalized service for our clients is really second to none!</p>
                    </li>
                    <li>
                    <strong>We Know How to Work Within Your Budget</strong>
                    <p>WYN is ultimately here to help your business succeed. We understand corporate budgets & finances, and we can deliver the best technological solution to meet both your needs and your budget!</p>
                    </li>
                    <li>
                    <strong>Fast, Responsive 24/7 Customer Support</strong>
                    <p>Unlike some other technology providers, WYN is committed to be here for you for the long run. We actually answer the phone when you call, and we’ll respond promptly to your needs 24/7.</p>
                    </li>
                    </ul>
                </MarkdownContent>
             <MarkdownContent>
                <h2>Got Questions? Ready to Get Started?</h2>
                <p>Need to plan some business technology upgrades for your manufacturing facility? WYN Technologies is happy to come out and provide a free initial site evaluation and consultation. Got some other specific questions? Ready to get started? Connect with us today at <a href="tel:3368990555">(336) 899-0555</a>, and let’s make it happen for you!</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
       </div>
      </Section>
    </Layout>
  )
}
export default ManufacturingPage
